import { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Styles from './UserMasonry.module.scss';

const TalentItem = dynamic(() => import('./MasonryItemTalent'), { ssr: false });

const Container = ({ items, isMobile, bottom, className }) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile]);

  return (
    <>
      <div
        className={classNames(
          mobile ? Styles.scroll : Styles.portfolioList,
          Styles.talentPortfolioList,
          className
        )}>
        <div className={classNames(Styles.spacer)} />
        {items.map((item, index) => (
          <TalentItem key={index} item={item} />
        ))}
        {mobile && <div style={{ flex: '0 0 1px' }} />}
      </div>
      {mobile && <div className={Styles.removeScroll} />}
    </>
  );
};

Container.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  bottom: PropTypes.bool,
  className: PropTypes.string,
};

export default Container;
