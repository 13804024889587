import classNames from 'classnames';
import Text from '@hiredigital/ui/Text/Text';
import HeroButton from '../Hero/HeroButton';
import Img from '@components/Img';
import { Section, SectionContainer } from '@components/Section';

import Styles from './CallToAction.module.scss';

const TalentCta = ({ className }) => {
  return (
    <SectionContainer
      type={SectionContainer.Type.LIGHTBLUE}
      className={Styles.talentCta}
      anchor='talentcta'>
      <Section className={classNames(Section.Styles.innerContainerSection, className)}>
        <div className={classNames(Styles.box, Styles.small)}>
          <Img
            src={`/img/need-talent@2x.webp`}
            alt='image'
            className={Styles.imageItem}
            wrapperClassName={Styles.imageContainer}
          />

          <div className={Styles.textContainer}>
            <Text element='h2' size={Text.Size.SUBHEADLINE_LARGE} className={Styles.ctaHeadline}>
              {`Choose When and How You Work`}
            </Text>
            <Text element='p' className={Styles.ctaDescription}>
              {`Join thousands of talents who work with Hire Digital to
                      find the best roles in marketing, design, and software development.`}
            </Text>
            <HeroButton
              type={HeroButton.Type.BLUE}
              className={Styles.btnApply}
              title='Work with Talent'
              href={`/talent/register`}>
              {`Apply As a Talent`}
            </HeroButton>
          </div>
        </div>
      </Section>
    </SectionContainer>
  );
};

export default TalentCta;
