import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// import TalentHero from '@components/PageComponents/Hero/TalentHero/TalentHero';
import Header from '@components/Header/TalentHeader';
import MainLayout from '@components/Layout/Layout';
import LoadingArea from '@hiredigital/ui/LoadingArea';
import { getStoriesBySlug } from '@helpers/posts';
import { getUsers, mergeCardUsersData, limitUsersData } from '@helpers/users';
import { replaceJson } from '@helpers/utils';
import TalentHero from '@components/PageComponents/Hero/TalentHero/TalentHero';
import TalentExplainer from '@components/PageComponents/TalentExplainer/TalentExplainer';
import LogoSection from '@components/PageComponents/LogoSection/LogoSection';

const TalentCta = dynamic(() => import('@components/PageComponents/CallToAction/TalentCta'));

const TalentWhyJoin = dynamic(
  () => import('@components/PageComponents/TalentWhyJoin/TalentWhyJoin'),
  {
    loading: () => <LoadingArea height={451} />,
    ssr: false,
  }
);

const TalentWorkflow = dynamic(() => import('@components/PageComponents/Workflow/TalentWorkflow'), {
  loading: () => <LoadingArea height={486} />,
  ssr: false,
});
const TalentRequests = dynamic(
  () => import('@components/PageComponents/TalentRequests/TalentRequests'),
  {
    loading: () => <LoadingArea height={528} />,
    ssr: false,
  }
);
const TalentTestimonial = dynamic(
  () => import('@components/PageComponents/TalentTestimonial/TalentTestimonial'),
  {
    ssr: false,
  }
);
const TalentWhatToExpect = dynamic(
  () => import('@components/PageComponents/TalentExplainer/TalentWhatToExpect'),
  {
    ssr: false,
  }
);
const PostSection = dynamic(() => import('@components/PageComponents/PostSection/PostSection'), {
  ssr: false,
});
const RightTriangle = dynamic(() => import('@components/Parallax/RightTriangle'), {
  ssr: false,
});
const LeftTriangle = dynamic(() => import('@components/Parallax/LeftTriangle'), {
  ssr: false,
});

const Talent = ({
  hero,
  head,
  footer,
  customerNames,
  cardUsers,
  testimonials,
  posts,
  postData,
  ...props
}) => {
  return (
    <MainLayout
      header={Header}
      footerData={footer}
      pageHead={head}
      variant={MainLayout.Variants.TALENT}>
      <TalentHero
        customerNames={customerNames}
        users={cardUsers}
        title={hero.title}
        description={hero.description}
      />
      <LogoSection clients={customerNames} />
      <RightTriangle />
      <TalentExplainer />

      <TalentWhyJoin />
      <LeftTriangle />
      <TalentWorkflow />
      <TalentRequests />

      <TalentTestimonial testimonials={testimonials} />
      <TalentWhatToExpect />
      <TalentCta />
      <PostSection posts={posts} postData={postData} />
    </MainLayout>
  );
};

Talent.propTypes = {
  hero: PropTypes.object,
  head: PropTypes.object,
  footer: PropTypes.object,
  cardUsers: PropTypes.array,
};

export default Talent;

export const getStaticProps = async (context) => {
  const pageData = replaceJson(require('@data/pages/talent.json'));
  const testimonials = replaceJson(require('@data/testimonials.json'));

  const { users = [], ...restPageData } = pageData;
  const jsonUsers = [];
  const userUuids = users.filter(({ uuid }) => !!uuid).map(({ uuid }) => uuid);
  let apiUsers = [];
  if (userUuids.length) {
    apiUsers = await getUsers(userUuids);
  }

  const posts = pageData.posts;
  const articles = posts.map(({ slug }) => slug);
  const postData = await getStoriesBySlug(articles);

  const cardUsers = limitUsersData(mergeCardUsersData(jsonUsers, users, apiUsers));

  return {
    props: { ...restPageData, cardUsers, testimonials, postData },
    revalidate: 7200,
  };
};
