import { Section, SectionContainer } from '@components/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './TalentExplainer.module.scss';

const TalentExplainer = () => {
  return (
    <SectionContainer type={SectionContainer.Type.LIGHTBLUE}>
      <Section className={Section.Styles.ruler} title={`What is Hire Digital?`}>
        <div className={Styles.info}>
          <div className={Styles.itemLeft}>
            <Text color={Text.Color.BLUE} className={Styles.itemTitle}>
              {`Hire Digital is a network of the best content marketing talent and digital experts around the world.`}
            </Text>
          </div>
          <div className={Styles.itemRight}>
            <Text>
              {`Hire Digital works with some of the fastest growing, transformative businesses worldwide. Enabled by technology, Hire Digital matches its network of experienced talent to relevant projects from brands including Samsung, Grab, Unilever, Expedia, Carousell and Philips. With Hire Digital, talent can focus on what they love to do, while we take care of the rest.`}
            </Text>
          </div>
        </div>
      </Section>
    </SectionContainer>
  );
};

export default TalentExplainer;
