import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Hero from '../Hero';
import HeroButton from '../HeroButton';
import TalentMasonry from '@components/UserMasonry/TalentMasonry';
import Styles from './Styles.module.scss';

const TalentHero = ({ users, order, title, description, customerNames, hideCustomers }) => {
  return (
    <Fragment>
      <Hero parent={Hero.Page.TALENT}>
        <div className={Styles.heroContainer}>
          <div className={Hero.Styles.heroLeft}>
            <div>
              <h1 className={Styles.heroTitle}>{title}</h1>
              <p className={Styles.heroDescription}>{description}</p>
            </div>

            <div className={Styles.heroCta}>
              <HeroButton type={HeroButton.Type.WHITE} title='' to='/talent/register'>
                {`Apply Now for Free`}
              </HeroButton>
            </div>
          </div>
          <div className={Hero.Styles.heroRight}>
            {users && <TalentMasonry bottom users={users} order={order || users} />}
          </div>
        </div>
      </Hero>
    </Fragment>
  );
};

TalentHero.propTypes = {
  users: PropTypes.array,
  order: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  talentCtaLinkTo: PropTypes.string,
  talentCtaLabel: PropTypes.string,
  brandCtaLinkTo: PropTypes.string,
  brandCtaLabel: PropTypes.string,
  customerNames: PropTypes.array,
};

export default TalentHero;
